import { useState, createContext } from "react";
import { useTranslations } from "@divide/ois-react-hooks";
import { useAuthentication } from "@divide/ois-react-components";
import { useCultureCode, useGetSettingsGroup, useGetConfigurationGroup, useGetUserHasAccess, useGetUserBranch } from "@divide/retailsuite-react-hooks";
import { useResolveRoutesByAuthentication } from "hooks/routing/useResolveRoutesByAuthentication";
import { Loader } from "@divide/retailsuite-react-components";
import { privateRoutes, publicRoutes } from "constants/routes";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import translations from "constants/translations";
import useSetCdnDomainToStorage from "hooks/general/useSetCdnDomainToStorage";
import "./App.scss";

export const AppContext = createContext();

export function App() {
    const { routes, element } = useResolveRoutesByAuthentication(useAuthentication, privateRoutes, publicRoutes);
    const webshopSettings = useGetSettingsGroup(useAuthentication, appConfig, "Webshop");
    const generalConfiguration = useGetConfigurationGroup(useAuthentication, appConfig, "General");
    const ocConfiguration = useGetConfigurationGroup(useAuthentication, appConfig, "OC");

    useSetCdnDomainToStorage(webshopSettings.CdnDomain);

    const [triggerReload] = useState(0);
    const { loading } = useAuthentication();
    const { cultureCode, setCultureCode } = useCultureCode(storageKeys.cultureCode);
    const { translation } = useTranslations(cultureCode, translations);
    const userAccess = useGetUserHasAccess(useAuthentication, 3);
    const userBranch = useGetUserBranch(useAuthentication, appConfig);

    return loading ? (
        <Loader fullscreen={true} text={translation("messages.loading.default")} />
    ) : (
        <AppContext.Provider
            value={{
                cultureCode,
                setCultureCode,
                translation,
                userAccess,
                userBranch,
                webshopSettings,
                generalConfiguration,
                triggerReload,
                ocConfiguration,
                routes
            }}>
            {element}
        </AppContext.Provider>
    );
}